import React from "react";
import { StyleSheet, View, Text, Image } from "@react-pdf/renderer";
import LogoInspiration from "../../../img/LOGO_INSPIRATION_PRODUCTIONS_NOIR.png";
import LogoImmersive from "../../../img/LOGO_IMMERSIVE_NOIR.png";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    height: "34%",
    maxHeight: "34%",
    marginBottom: "10px",
  },
  leftColumn: {
    flexDirection: "column",
    width: "47.5%",
    paddingTop: 5,
    paddingRight: 15,
    marginRight: "2.5%",
  },
  rightColumn: {
    flexDirection: "column",
    width: "47.5%",
    paddingTop: 5,
    paddingRight: 15,
    marginLeft: "2.5%",
  },
  borderH: {
    border: "1px solid black",
    width: "100%",
    textAlign: "center",
    paddingVertical: "2px",
  },
  borderBH: {
    border: "1px solid black",
    borderTop: "0px solid black",
    width: "100%",
    textAlign: "center",
    paddingVertical: "2px",
  },
  borderB: {
    border: "1px solid black",
    borderTop: "0px solid black",
    width: "100%",
    textAlign: "left",
    paddingVertical: "2px",
  },
  topSpace: {
    marginTop: "15px",
  },
  interlocuteur: {
    marginTop: "10px",
  },
  contact: {
    marginTop: "15px",
  },
  image: {
    maxHeight: "100pt",

    objectFit: "contain",
    marginLeft: "25%",
    marginTop: "-5%",
    marginBottom: "5%",
  },
  nomEntite: {
    marginLeft: "15%",
    marginTop: "0px",
  },
  textRight: {
    marginLeft: "15%",
    marginTop: "30px",
  },
  textLeft: {
    marginLeft: "5%",
  },
});

const Header = (props) => {
  var factureData = props.data.devis;
  var clientData = props.data.clientData;
  var logoType = props.data.logo;

  const getLogoImage = () => {
    switch (logoType) {
      case "inspiration":
        return LogoInspiration;
      case "immersive":
        return LogoImmersive;
      case "none":
      default:
        return null;
    }
  };

  const logoImage = getLogoImage();

  return (
    <View style={styles.container} fixed>
      <View style={styles.leftColumn}>
        <View style={styles.textLeft}>
          <Text>Inspiration Productions</Text>
          <Text>23, rue Arago</Text>
          <Text>93400 SAINT-OUEN</Text>
          <Text>01 80 89 30 50</Text>
        </View>
        <View style={styles.topSpace}>
          <Text style={styles.borderH}>FACTURE N°{props.data.nomFacture}</Text>
          <Text style={styles.borderBH}>{props.data.date}</Text>
        </View>
        <View style={styles.topSpace}>
          <Text style={styles.borderH}>Siège Social</Text>
          <View style={styles.borderB}>
            <Text>{clientData.raisonSociale}</Text>
            <Text>{clientData.adressePersonne}</Text>
            <Text>
              {clientData.codePostalPersonne} {clientData.villePersonne}
            </Text>
            <Text>N° TVA intracom : {clientData.numeroTvaIntra}</Text>
          </View>
        </View>
        <Text style={styles.interlocuteur}>
          Votre interlocuteur : Aymeric GARDEY
        </Text>
        <View style={styles.contact}>
          <Text>Contact Client : {factureData.contactClient}</Text>
          <Text>N° Commande : {props.data.commande}</Text>
          <Text>
            {factureData.nom} - {factureData.reference}
          </Text>
        </View>
      </View>
      <View style={styles.rightColumn}>
        {logoImage && <Image src={logoImage} style={styles.image}></Image>}
        <Text style={styles.nomEntite}>{clientData.nomEntite}</Text>
        <View style={styles.textRight}>
          <Text>{clientData.adresse1}</Text>
          <Text>{clientData.adresse2}</Text>
          <Text>{clientData.adresse3}</Text>
          <Text>
            {clientData.codePostal} {clientData.ville} - {clientData.pays}
          </Text>
        </View>
        <View style={styles.textRight}>
          <Text>N° Fournisseur : {factureData.fournisseur}</Text>
        </View>
      </View>
    </View>
  );
};

export { Header };


import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

const LogoSelector = ({ selectedLogo, onLogoChange }) => {
  const handleLogoChange = (event) => {
    onLogoChange(event.target.value);
  };

  return (
    <FormControl sx={{ ml: 2, mt: 1, minWidth: 200 }}>
      <InputLabel id="logo-select-label">Choisir Logo</InputLabel>
      <Select
        labelId="logo-select-label"
        id="logo-select"
        value={selectedLogo}
        label="Choisir Logo"
        onChange={handleLogoChange}
      >
        <MenuItem value="inspiration">Inspiration Productions</MenuItem>
        <MenuItem value="immersive">Immersive Studios</MenuItem>
        <MenuItem value="none">Aucun logo</MenuItem>
      </Select>
      <FormHelperText>Sélectionnez un logo à afficher</FormHelperText>
    </FormControl>
  );
};

export default LogoSelector;

import React from "react";
import { GET, POST, UPDATE /*, DELETE*/ } from "../../utils/AxiosRequest";

import classNames from "classnames";
import styles from "./Tasks.module.css";

import {
  Grid,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Switch,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import EditIcon from "@mui/icons-material/Edit";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";

import { styled } from "@mui/system";

import Session from "react-session-api";

import { SEND_NOTIFICATION_SLACK } from "../../utils/Slack";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export const TasksList = React.forwardRef((props, ref) => {
  const theme = useTheme();

  const pepoleData = JSON.parse(Session.get("pepoleData"));

  const [employeesList, setEmployeesList] = React.useState([]);
  const [taskTemplates, setTaskTemplates] = React.useState([]);
  const [autreTaskTemplate, setAutreTaskTemplate] = React.useState([]); // Specific 'autre' task in table templateTask

  const [filters, setFilters] = React.useState({
    employees: JSON.parse(
      !pepoleData.employeesTasksFilter || pepoleData.employeesTasksFilter === ""
        ? "[-1]"
        : pepoleData.employeesTasksFilter,
    ),
    selectedProject: -1,
    uncheckedOnly: true,
    hideTransverse: true,
  });

  const changeEmployeesTasksFilter = (employees) =>
    setFilters({ ...filters, employees });
  const changeUncheckedOnly = (uncheckedOnly) =>
    setFilters({ ...filters, uncheckedOnly });
  const changeHideTransverse = (hideTransverse) =>
    setFilters({ ...filters, hideTransverse });
  const changeSelectedProjectFilter = (selectedProject) => {
    console.log("changeSelectedProjectFilter: " + selectedProject);
    setFilters({ ...filters, selectedProject });
  };

  const [projectsList, setProjectsList] = React.useState([]);
  const [tasksList, setTasksList] = React.useState([]);
  const [selectedTask, setSelectedTask] = React.useState();

  const filterBarRef = React.useRef(null);
  const tasksRefs = React.useRef([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        loadEmployees();
        loadProjects();
        loadTaskTemplatesAndTasks();
      } catch (error) {
        console.error("Erreur lors de la récupération des données", error);
      }
    };
    fetchData();

    // Cleanup de l'effet si nécessaire
    return () => {
      // Instructions de cleanup ici si besoin
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  function loadEmployees() {
    var promises = [GET("projects/getAllEmployeInfo", {})];
    return Promise.all(promises).then((data) => {
      setEmployeesList(data[0]);
    });
  }

  function loadProjects() {
    // First get templatesoustache so that its possible to populate a task with all its possible soustache templates
    GET("projects/getAllProjects", {}).then((projectsData) => {
      projectsData = projectsData.sort((a, b) => a.nom.localeCompare(b.nom));
      projectsData = projectsData.sort((a, b) => a.transverse - b.transverse);

      setProjectsList(projectsData);
    });
  }

  function loadTaskTemplatesAndTasks() {
    // First get templatesoustache so that its possible to populate a task with all its possible soustache templates
    GET("projects/getSubTaskTemplate", {}).then((templatesoustachedata) => {
      GET("projects/getAllTasksTemplate", {}).then((data) => {
        var categories = [];
        data.forEach((taskTemplate) => {
          if (taskTemplate.nom === "Autre") {
            // Set the 'autre' category in case of undefined at task creation
            setAutreTaskTemplate(taskTemplate);
          }

          taskTemplate.types = [];
          templatesoustachedata.forEach((el) => {
            if (el.idTache === taskTemplate.id) {
              taskTemplate.types.push(el);
              if (el.nom === "Autre") {
                taskTemplate.autreType = el;
              }
            }
          });

          if (
            !taskTemplate.nom.includes("Tournage") &&
            !taskTemplate.nom.includes("Deadline")
          ) {
            categories.push(taskTemplate);
          }
        });
        setTaskTemplates(categories.sort((a, b) => a.id - b.id));

        // Load tasks
        setSelectedTask(null);
        var promises = [
          filters.uncheckedOnly
            ? GET("projects/getUncheckedUndatedSubTasks")
            : GET("projects/getUndatedSubTasks"),
        ];
        return Promise.all(promises).then((data) => {
          var filteredTasks = data[0].filter(
            (el) =>
              filters.employees.includes(-1) ||
              (filters.employees.includes(-2) && el.employe.length === 0) ||
              JSON.parse(el.employe).some((pepole) =>
                filters.employees.includes(pepole),
              ),
          );

          if (filters.selectedProject !== -1) {
            filteredTasks = filteredTasks.filter(
              (el) => el.idProjet === filters.selectedProject,
            );
          }

          if (filters.hideTransverse) {
            filteredTasks = filteredTasks.filter(
              (el) => !el.transverse || el.transverse === 0,
            );
          }

          filteredTasks.forEach((t, index) => {
            // Set previous and next subtasks
            t.previous =
              index === 0
                ? filteredTasks[filteredTasks.length - 1]
                : filteredTasks[index - 1];
            t.next =
              index === filteredTasks.length - 1 ? 0 : filteredTasks[index + 1];
            t.employe = JSON.parse(t.employe);

            categories.forEach((cat) => {
              if (cat.id === t.idTemplateTache) t.category = cat;
            });

            // May happen that a task has been associated to a category that usually requires a date (tournage, deadline) without having a date defined
            // => appears in this tasks list (no date) but doesn't correspond to any expected category
            if (!t.category) {
              t.category = autreTaskTemplate;
              t.warningNoValidCategory = true;
            }
          });

          setTasksList(filteredTasks);
        });
      });
    });
  }

  function getTaskTemplateById(id) {
    return taskTemplates.find((element) => element.id === id);
  }

  function getSubtaskTemplateById(category, id) {
    if (category.types)
      return category.types.find((element) => element.id === id);
  }

  function selectTask(task) {
    if (task === selectedTask) {
      setSelectedTask(null);
    } else {
      setSelectedTask(task);
    }
  }

  function addTask(project, category, type, text, employees) {
    // Increment all subtask orderindex if selectedTask
    if (selectedTask) {
      var paramsIncrementOrderIndex = {
        orderIndex: selectedTask.orderIndex,
      };
      UPDATE(
        "projects/incrementAllOrderIndexesFromSubTaskOrderIndex",
        paramsIncrementOrderIndex,
      ).then(() => {
        insertNewTaskInDb(project, category, type, text, employees);
      });
    } else {
      insertNewTaskInDb(project, category, type, text, employees);
    }
  }

  function insertNewTaskInDb(
    project,
    category,
    type,
    text,
    employees,
    orderIndex,
  ) {
    var paramsCategory = {
      // Category here means old table tache (should-be-removed table)
      etat: 0,
      idTache: category ? category.id : autreTaskTemplate.id, // category 'autre' if undefined
      idProjet: project ? project.id : -1,
    };

    return POST("projects/insertNewTask", paramsCategory).then(
      (newCategory) => {
        // Category means old table tache (should be removed table)
        var params = {
          idSousTache: type
            ? type.id
            : getTaskTemplateById(category ? category.id : autreTaskTemplate.id)
                .autreType.id, // type 'Autre' in case of undefined type
          etat: 0,
          employe: JSON.stringify(employees),
          idTache: newCategory.insertId,
          idProjet: project ? project.id : -1,
          texte: !type || type.nom === "Autre" ? text : "",
        };
        POST("projects/insertNewSubTask", params).then((result) => {
          var subtaskParams = {
            id: result.insertId,
            orderIndex: selectedTask
              ? selectedTask.orderIndex
              : result.insertId,
          };
          UPDATE("projects/updateSubtaskDefaultOrderIndex", subtaskParams).then(
            () => {
              loadTaskTemplatesAndTasks();
            },
          );
        });

        // Notify if a project is set
        if (project) {
          const pepoleData = JSON.parse(Session.get("pepoleData"));
          var messageAdded =
            "<sender> t'a assigné à la sous-tâche " +
            (text && text !== "" ? text : type.nom) +
            " appartenant à la tâche " +
            (category ? category.nom : "Autre") +
            " du projet " +
            project.nom;
          var sender = String(pepoleData.prenom).toLowerCase();

          employees.forEach(async (element) => {
            var tmp = employeesList.find((item) => item.id === element);
            if (tmp === undefined) return;

            var user = String(tmp.prenom).toLowerCase();

            var url = new URL(
              window.location.href.replace(window.location.search, ""),
            );
            url.searchParams.append("id", project.id);
            url.searchParams.append("task", newCategory.insertId);

            await SEND_NOTIFICATION_SLACK(sender, user, messageAdded, url);
          });
        }
      },
    );
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    width: "100%",
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    paddingBottom: 1.5,
    margin: `0 0 0 0`,

    // change background colour if dragging
    background: isDragging ? "lightgrey" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.source.index !== result.destination.index) {
      var params = {
        source: tasksList[result.source.index].orderIndex,
        destination: tasksList[result.destination.index].orderIndex,
      };
      await UPDATE("projects/shiftOrderIndexesForFutherInsertion", params).then(
        () => {
          var updateParams = {
            newOrderIndex: tasksList[result.destination.index].orderIndex,
            id: tasksList[result.source.index].id,
          };
          UPDATE("projects/updateSubtaskOrderIndexForId", updateParams).then(
            () => {
              loadTaskTemplatesAndTasks();
            },
          );
        },
      );
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Stack alignItems="left" sx={{ width: "100%" }} spacing={1}>
        <Stack sx={{ width: "100%" }} direction="column">
          <FilterBar
            ref={filterBarRef}
            filters={filters}
            pepoleList={employeesList}
            projectsList={projectsList}
            changeEmployeesTasksFilter={changeEmployeesTasksFilter}
            changeSelectedProjectFilter={changeSelectedProjectFilter}
            changeUncheckedOnly={changeUncheckedOnly}
            changeHideTransverse={changeHideTransverse}
            taskTemplates={taskTemplates}
            getTaskTemplateById={getTaskTemplateById}
            addTask={addTask}
          />
        </Stack>
        <Box sx={{ height: "70vh", overflowY: "scroll" }}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <Grid
                  sx={{ maxWidth: "1150px" }}
                  gridAutoFlow
                  container
                  columns={16}
                  spacing={0.5}
                  paddingX={4}
                  paddingBottom={2}
                  marginY={2}
                >
                  {tasksList.map((task, index) => (
                    <Draggable
                      key={task.id}
                      draggableId={"task" + task.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          id={"task" + task.id}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <TaskLine
                            key={task.id}
                            ref={(element) =>
                              (tasksRefs.current[index] = element)
                            }
                            taskData={task}
                            employeesList={employeesList}
                            theme={theme}
                            projectsList={projectsList}
                            loadTaskTemplatesAndTasks={
                              loadTaskTemplatesAndTasks
                            }
                            selectTask={selectTask}
                            selectedTask={selectedTask}
                            provided={provided}
                            taskTemplates={taskTemplates}
                            getTaskTemplateById={getTaskTemplateById}
                            getSubtaskTemplateById={getSubtaskTemplateById}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              </div>
            )}
          </Droppable>
        </Box>
      </Stack>
    </DragDropContext>
  );
});

const TaskLine = (props) => {
  const { taskData } = props;

  const [selectedProject, setSelectedProject] = React.useState(
    taskData.idProjet,
  );
  const [selectedPepole, setSelectedPepole] = React.useState(taskData.employe);
  const changeSelectedProject = (event) => {
    setSelectedProject(event.target.value);
    assignProject(event.target.value);
  };
  const changeSelectedPepole = (event) => setSelectedPepole(event.target.value);

  const [editOpen, setEditOpen] = React.useState(false);

  const [category, setCategory] = React.useState(taskData.category);
  const [type, setType] = React.useState(
    props.getSubtaskTemplateById(taskData.category, taskData.idSoustache),
  );
  const [text, setText] = React.useState(taskData.texte);
  const changeCategory = (event) => {
    setCategory(props.getTaskTemplateById(event.target.value));
    setType(props.getTaskTemplateById(event.target.value).autreType);
  };
  const changeType = (event) => {
    setType(props.getSubtaskTemplateById(category, event.target.value));
  };

  function updateCheck(id, state) {
    return UPDATE("projects/updateSubTaskStatus", {
      etat: state === 0 ? 1 : 0,
      subTaskID: id,
    }).then(props.loadTaskTemplatesAndTasks());
  }

  function GetUrlForNotif() {
    var url = new URL(window.location.href.replace(window.location.search, ""));
    url.searchParams.append("id", taskData.idProjet);
    url.searchParams.append("task", taskData.idTemplateTache);
    return url.href;
  }

  function assignProject(project) {
    var params = {
      idProjet: project,
      idProjet2: project,
      subtaskId: taskData.id,
    };
    UPDATE("projects/updateTaskAndSubTaskProject", params);
  }

  function assignEmployees() {
    var params = { employe: JSON.stringify(selectedPepole), id: taskData.id };
    UPDATE("projects/updateSpecificSubtask", params).then((_) => {
      var pepoleToNotify = [];
      taskData.employe.forEach((element) => {
        if (!props.employeesList.includes(element)) {
          pepoleToNotify.push({ id: element, added: true });
        }
      });
      props.employeesList.forEach((element) => {
        if (!taskData.employe.includes(element)) {
          pepoleToNotify.push({ id: element, added: false });
        }
      });

      const pepoleData = JSON.parse(Session.get("pepoleData"));
      var messageAdded =
        "<sender> t'a assigné à la sous-tâche " +
        taskData +
        " appartenant à la tâche " +
        taskData.nomTache +
        " du projet " +
        taskData.nomProjet;
      var messageRemoved =
        "<sender> t'a retiré de la sous-tâche " +
        taskData +
        " appartenant à la tâche " +
        taskData.nomTache +
        " du projet " +
        taskData.nomProjet;
      var sender = String(pepoleData.prenom).toLowerCase();

      pepoleToNotify.forEach(async (element) => {
        var tmp = props.employeesList.find((item) => item.id === element.id);
        if (tmp === undefined) return;

        var user = String(tmp.prenom).toLowerCase();
        var url = GetUrlForNotif(taskData);

        if (element.added) {
          await SEND_NOTIFICATION_SLACK(sender, user, messageAdded, url);
        } else {
          await SEND_NOTIFICATION_SLACK(sender, user, messageRemoved, url);
        }
      });
    });
  }

  function handleEditClose(save) {
    if (save) {
      UPDATE("projects/updateTemplateTaskForSubTaskId", {
        idTache: category.id,
        id: taskData.id,
      }).then((_) => {
        UPDATE("projects/updateSubTaskTemplateSubTaskAndText", {
          idSousTache: type.id,
          texte: type.estTexte ? text : "",
          id: taskData.id,
        }).then((_) => {
          return props.loadTaskTemplatesAndTasks();
        });
      });
    }
    setEditOpen(false);
  }

  return (
    <Grid item xs={16} style={{ cursor: "pointer" }}>
      <Item
        onClick={() => props.selectTask(taskData)}
        style={{
          backgroundColor:
            props.selectedTask && taskData.id === props.selectedTask.id
              ? "aliceblue"
              : "unset",
        }}
        sx={{ paddingRight: 2, paddingY: 0 }}
        key={taskData.id}
      >
        <Stack direction="row" width="100%" spacing={2} alignItems="center">
          <div
            style={{ paddingTop: "4px" }}
            {...props.provided.dragHandleProps}
          >
            <DragIndicatorIcon />
          </div>
          <Typography
            title={taskData.texte !== "" ? taskData.texte : taskData.nom}
            width="100%"
            variant="body2"
            color={props.theme.palette.text.primary}
          >
            {taskData.texte && taskData.texte !== ""
              ? taskData.texte
              : taskData.nom}
          </Typography>

          <IconButton
            color="primary"
            size="small"
            onClick={(_) => setEditOpen(true)}
          >
            <EditIcon />
          </IconButton>
          <Dialog
            maxWidth="md"
            open={editOpen}
            onClose={(_) => handleEditClose(false)}
          >
            <DialogTitle>
              <Typography fontSize="1.2em" color="primary">
                Renommer la tâche
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Stack direction="row" width="100%">
                {taskData.warningNoValidCategory && (
                  <Tooltip
                    title="La catégorie actuellement associée (montage, deadline,...) devrait être associée à une date. Après validation, elle sera changée par ce choix."
                    placement="right"
                  >
                    <WarningTwoToneIcon />
                  </Tooltip>
                )}
                <TextField
                  sx={{ mb: 2.1 }}
                  fullWidth
                  select
                  label="Catégorie"
                  variant="standard"
                  value={category.id}
                  onChange={changeCategory}
                >
                  {props.taskTemplates.map((t, i) => (
                    <MenuItem value={t.id} key={i}>
                      {t.nom}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              {category && type && (
                <TextField
                  sx={{ mb: 2.1 }}
                  fullWidth
                  select
                  label={"Type"}
                  variant="standard"
                  value={type.id}
                  onChange={changeType}
                >
                  {category.types &&
                    category.types.map((t, i) => (
                      <MenuItem value={t.id} key={i}>
                        {t.nom}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              {(!type || !type.nom || "Autre" === type.nom) && (
                <TextField
                  fullWidth
                  label="Texte"
                  variant="standard"
                  value={text}
                  onChange={(event) => setText(event.target.value)}
                ></TextField>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={(_) => handleEditClose(false)}>Annuler</Button>
              <Button onClick={(_) => handleEditClose(true)} color="success">
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <Select
            style={{ minWidth: "20%", maxWidth: "20%" }}
            sx={{ fontSize: "small" }}
            variant="standard"
            value={selectedProject}
            onChange={changeSelectedProject}
          >
            {props.projectsList.map((project, i) => (
              <MenuItem value={project.id} key={i}>
                {project.id === -1 ? "" : project.nom}
              </MenuItem>
            ))}
          </Select>
          <Select
            style={{ minWidth: "16%", maxWidth: "16%" }}
            sx={{ fontSize: "small" }}
            variant="standard"
            multiple
            value={selectedPepole}
            onChange={changeSelectedPepole}
            onClose={async () => await assignEmployees()}
          >
            {props.employeesList.map((employee, i) => (
              <MenuItem value={employee.id} key={i}>
                {employee.prenom}
              </MenuItem>
            ))}
          </Select>
          <Button
            size="sm"
            onClick={() => updateCheck(taskData.id, taskData.etat)}
            color={taskData.etat === 0 ? "success" : "warning"}
          >
            <Typography key={taskData.id} variant="body2">
              {taskData.etat === 0 ? "check" : "uncheck"}
            </Typography>
          </Button>
          {/* <IconButton color="error" onClick={handleOpenDeleteConfirmation}>
          <DeleteForeverTwoToneIcon />
        </IconButton>
				<Dialog maxWidth="md" open={deleteConfirmationOpen} onClose={handleCloseDeleteConfirmation}>
					<DialogTitle>
						Supprimer la tâche { (taskData.texte !== '') ? taskData.texte : taskData.nom } ?
					</DialogTitle>
					<DialogActions>
						<Button onClick={ _ => handleCloseDeleteConfirmation(null)}>Annuler</Button>
						<Button onClick={ _ => handleCloseDeleteConfirmation(taskData)} color="success">Ok</Button>
					</DialogActions>
				</Dialog> */}
        </Stack>
      </Item>
    </Grid>
  );
};

export const FilterBar = React.forwardRef((props, ref) => {
  const isDesktop = useMediaQuery("(min-width:1065px)");
  const { pepoleList } = props;
  const [selectedPeople, setSelectedPeople] = React.useState(
    props.filters.employees,
  );
  const [selectedProject, setSelectedProject] = React.useState(
    props.filters.selectedProject,
  );
  const [uncheckedTasks, setUncheckedTasks] = React.useState(
    props.filters.uncheckedOnly,
  );
  const [hideTransverse, setHideTransverse] = React.useState(
    props.filters.hideTransverse,
  );

  let pepoleData = JSON.parse(Session.get("pepoleData"));

  const changeSelectedPeople = (data) => {
    var array = [];
    if (data.length === 0 || data.at(-1) === -1) {
      array = [-1];
    } else {
      var allIndex = data.findIndex((element) => element === -1);
      if (allIndex !== -1) {
        data.splice(allIndex, 1);
      }
      array = data;
    }

    props.changeEmployeesTasksFilter(array);
    setSelectedPeople(array);

    // Only for session storage below: Cancels multiselection if "Tout le monde" has been selected
    if (data.length > 1 && data.includes(-1)) {
      data = [-1];
    }

    // Save tasks screen selection (employeesTasksFilter select) in session storage - Firstly the pepoleData is reread in case it could have change in between
    pepoleData = JSON.parse(Session.get("pepoleData"));
    pepoleData.employeesTasksFilter = JSON.stringify(data);
    Session.set("pepoleData", JSON.stringify(pepoleData));
  };

  const changeSelectedProjectFilter = (data) => {
    props.changeSelectedProjectFilter(data);
    setSelectedProject(data);
  };

  const changeUncheckedTasks = (uncheckTasks) => {
    props.changeUncheckedOnly(uncheckTasks);
    setUncheckedTasks(uncheckTasks);
  };

  const changeHideTransverse = (hide) => {
    props.changeHideTransverse(hide);
    setHideTransverse(hide);
  };

  if (isDesktop) {
    return (
      <Paper elevation={1}>
        <Stack direction="row">
          <Stack
            direction="row"
            spacing={4}
            marginX={4}
            marginY={2}
            alignItems="center"
            sx={{ overflowX: "auto" }}
          >
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel>Tâches de</InputLabel>
              <Select
                style={{ marginTop: 0 }}
                multiple
                variant="standard"
                value={selectedPeople}
                onChange={(event) => changeSelectedPeople(event.target.value)}
              >
                <MenuItem key={-1} value={-1}>
                  Tout le monde
                </MenuItem>
                <MenuItem key={-2} value={-2}>
                  Non assigné
                </MenuItem>
                {pepoleList.map((pepole) => (
                  <MenuItem key={pepole.id} value={pepole.id}>
                    {pepole.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, width: 500 }}>
              <InputLabel>Projet</InputLabel>
              <Select
                style={{ marginTop: 0 }}
                variant="standard"
                value={selectedProject}
                onChange={(event) =>
                  changeSelectedProjectFilter(event.target.value)
                }
              >
                <MenuItem key={-1} value={-1}>
                  Tous les projets
                </MenuItem>
                {props.projectsList.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.nom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <TaskCreation
            pepoleList={pepoleList}
            projectsList={props.projectsList}
            taskTemplates={props.taskTemplates}
            getTaskTemplateById={props.getTaskTemplateById}
            addTask={props.addTask}
          />
          <Box sx={{ width: "100%" }}></Box>
          <Stack direction="column" alignItems="center" align="right">
            <Stack
              spacing={1.5}
              direction="row"
              alignItems="center"
              align="right"
            >
              <Typography style={{ whiteSpace: "nowrap" }} variant="body1">
                Masquer terminées
              </Typography>
              <Switch
                checked={uncheckedTasks}
                onChange={(event) => changeUncheckedTasks(!uncheckedTasks)}
              />
            </Stack>
            <Stack direction="row" alignItems="center" align="right">
              <Typography style={{ whiteSpace: "nowrap" }} variant="body1">
                Masquer transverses
              </Typography>
              <Switch
                checked={hideTransverse}
                onChange={(event) => changeHideTransverse(!hideTransverse)}
              />
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    );
  } else {
    return (
      <Box marginTop={2}>
        <Stack direction="column" spacing={0.5} alignItems="center">
          <FormControl
            sx={{ width: 300 }}
            className={classNames(styles.filterElement)}
          >
            <InputLabel
              sx={{ textAlign: "center", alignSelf: "center", height: "25px" }}
            >
              Projet de{" "}
            </InputLabel>
            <Select
              multiple
              variant="standard"
              label="Projet de"
              value={selectedPeople}
              onChange={(event) => changeSelectedPeople(event.target.value)}
            >
              <MenuItem key={-1} value={-1}>
                Tout le monde
              </MenuItem>
              <MenuItem key={-2} value={-2}>
                Non assigné
              </MenuItem>
              {pepoleList.map((pepole) => (
                <MenuItem key={pepole.id} value={pepole.id}>
                  {pepole.prenom + " " + pepole.nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack direction="row" alignItems="center" align="right">
            <TaskCreation
              pepoleList={pepoleList}
              projectsList={props.projectsList}
              taskTemplates={props.taskTemplates}
              getTaskTemplateById={props.getTaskTemplateById}
              addTask={props.addTask}
            />
            <Typography style={{ whiteSpace: "nowrap" }} variant="body1">
              Masquer terminées
            </Typography>
            <Switch
              checked={uncheckedTasks}
              onChange={(event) => changeUncheckedTasks(!uncheckedTasks)}
            />
            <Typography style={{ whiteSpace: "nowrap" }} variant="body1">
              Masquer transverses
            </Typography>
            <Switch
              checked={hideTransverse}
              onChange={(event) => changeHideTransverse(!hideTransverse)}
            />
          </Stack>
        </Stack>
      </Box>
    );
  }
});

export const TaskCreation = React.forwardRef((props, ref) => {
  const pepoleData = JSON.parse(Session.get("pepoleData"));
  const [open, setOpen] = React.useState(false);
  const [project, setProject] = React.useState();
  const [category, setCategory] = React.useState();
  const [type, setType] = React.useState("");
  const [text, setText] = React.useState("");
  const [employees, setEmployees] = React.useState([pepoleData.id]);
  const theme = useTheme();
  const changeProject = (event) => {
    setProject(event.target.value);
  };
  const changeCategory = (event) => {
    setCategory(event.target.value);
  };
  const changeType = (event) => {
    setType(event.target.value);
  };
  const changeEmployees = (event) => setEmployees(event.target.value);

  React.useEffect(() => {}, []);

  function HandleClose(save) {
    if (save) {
      props.addTask(project, category, type, text, employees);
    }
    setProject("");
    setCategory("");
    setType("");
    setText("");
    setOpen(false);
  }
  return (
    <div>
      <Button
        style={{ whiteSpace: "nowrap" }}
        variant="contained"
        sx={{ margin: 2.5 }}
        onClick={() => setOpen(true)}
      >
        Ajouter
      </Button>
      <Dialog maxWidth="md" open={open} onClose={() => HandleClose(false)}>
        <DialogTitle>
          <Typography fontSize="1.2em" color={theme.palette.text.primary}>
            Ajouter une tâche
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            select
            label="Projet"
            variant="standard"
            value={project}
            onChange={changeProject}
          >
            {props.projectsList.map((p, i) => (
              <MenuItem value={p} key={i}>
                {p.nom}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogContent>
          <TextField
            fullWidth
            select
            label="Catégorie"
            variant="standard"
            value={category}
            onChange={changeCategory}
          >
            {props.taskTemplates.map((t, i) => (
              <MenuItem value={t} key={i} selected={t.nom === "Autre"}>
                {t.nom}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        {category && (
          <DialogContent>
            <TextField
              fullWidth
              select
              label="Type"
              variant="standard"
              value={type}
              onChange={changeType}
            >
              {category &&
                category.types.map((t, i) => (
                  <MenuItem value={t} key={i}>
                    {t.nom}
                  </MenuItem>
                ))}
            </TextField>
          </DialogContent>
        )}
        {(!type.nom || "Autre" === type.nom) && (
          <DialogContent>
            <TextField
              fullWidth
              label="Texte"
              variant="standard"
              selected={text}
              onChange={(event) => setText(event.target.value)}
            ></TextField>
          </DialogContent>
        )}
        <DialogContent>
          <Select
            style={{ minWidth: "30%" }}
            variant="standard"
            multiple
            value={employees}
            onChange={changeEmployees}
          >
            {props.pepoleList.map((employee, i) => (
              <MenuItem value={employee.id} key={employee.id}>
                {employee.prenom}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => HandleClose(false)}>Retour</Button>
          <Button onClick={() => HandleClose(true)} color="success">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

import React from "react";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { SelectClient } from "./SelectClient";
import { LoadDevis } from "./LoadDevis";
import { ShowTarifs } from "./ShowTarifs";
import { AddPremadeLine } from "./AddPremadeLines";
import { DevisViewer } from "../DevisViewer";
import { Accompte } from "./Accompte";
import { ValidateDevis } from "./ValidateDevis";
import LogoSelector from "../../Administration/Views/LogoSelector";

const TOURNAGE = [
  { name: "REALISATEUR", quantity: 1 },
  { name: "CADREUR", quantity: 1 },
  { name: "PACK CAMERA", quantity: 1 },
];
const TOURNAGE_HAFLDAY = [
  { name: "REALISATEUR DEMI JOURNEE", quantity: 1 },
  { name: "CADREUR DEMI JOURNEE", quantity: 1 },
  { name: "PACK CAMERA DEMI JOURNEE", quantity: 1 },
];
const MONTAGE = [
  { name: "REALISATEUR", quantity: 0.25 },
  { name: "MONTEUR", quantity: 1 },
  { name: "STATION MONTAGE", quantity: 1 },
];

export const DevisButtons = React.forwardRef((props, ref) => {
  const [save, setSave] = React.useState(false);
  const [logo, _] = React.useState("none");

  React.useImperativeHandle(ref, () => ({
    canSave: (value) => setSave(value),
  }));
  return (
    <Stack marginTop={5} spacing={2}>
      <LogoSelector
        selectedLogo={props.selectedLogo}
        onLogoChange={props.onLogoChange}
      />
      <Button
        fullWidth
        variant="contained"
        onClick={() => window.location.reload(false)}
      >
        Supprimer Devis
      </Button>
      <SelectClient updateClient={props.updateClient} />
      <LoadDevis loadDevis={props.loadDevis} isDevis />
      <LoadDevis loadDevis={props.loadDevis} />
      <ShowTarifs reloadTarifs={props.reloadTarifs} />
      <AddPremadeLine
        name="Tournage"
        lines={TOURNAGE}
        halfDayLines={TOURNAGE_HAFLDAY}
        addPremadeLines={props.addPremadeLines}
      />
      <AddPremadeLine
        name="Pre-prod / Montage / Post-prod"
        buttonName="Montage"
        lines={MONTAGE}
        halfDayLines={[]}
        addPremadeLines={props.addPremadeLines}
      />
      <Button
        fullWidth
        variant="contained"
        onClick={() => props.addSpecialLine(0)}
      >
        Sous-Total
      </Button>
      <Button
        fullWidth
        variant="contained"
        onClick={() => props.addSpecialLine(1)}
      >
        Ligne Vide
      </Button>
      <Button
        fullWidth
        variant="contained"
        onClick={() => props.addSpecialLine(2)}
      >
        Ligne pour texte
      </Button>
      <Accompte
        updateAccompte={props.updateAccompte}
        getDataForPDF={props.getDataForPDF}
      />
      <DevisViewer
        save={save}
        getDataForPDF={props.getDataForPDF}
        setSaveOverride={props.setSaveOverride}
        showLogo={props.selectedLogo}
      />
      <ValidateDevis />
    </Stack>
  );
});

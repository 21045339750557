import React from "react";
import { GET } from "../../utils/AxiosRequest";

import Stack from "@mui/material/Stack";

import { DevisHeader } from "./DevisHeader/DevisHeader";
import { DevisBody } from "./DevisBody/DevisBody";
import { DevisButtons } from "./DevisButtons/DevisButtons";
import { Box } from "@mui/material";

import { useSearchParams } from "react-router-dom";

export const Devis = (props) => {
  const [saveOverride, setSaveOverride] = React.useState(false);
  const [logo, setLogo] = React.useState("inspiration");

  const headerRef = React.useRef(null);
  const bodyRef = React.useRef(null);
  const buttonsRef = React.useRef(null);

  const [queryParameters] = useSearchParams();

  function GetDevisFromDb(id) {
    return GET("factures/getDevisById", { id: id });
  }

  function handleLogoChange(value) {
    setLogo(value);
  }

  React.useEffect(() => {
    const devisId = queryParameters.get("id");
    if (devisId) {
      GetDevisFromDb(devisId).then((data) => {
        LoadDevis(data[0], true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function UpdateTotal(total) {
    headerRef.current.updateTotal(total);
  }

  function updateAccompte(data) {
    headerRef.current.updateDueDatesData(data);
  }

  function UpdateSaveButton() {
    buttonsRef.current.canSave(CheckSaveEnabled());
  }

  function UpdateClient(client) {
    return GET("devis/getCLientInfo", { clientId: client.id }).then((data) => {
      headerRef.current.updateClient(data[0]);
      buttonsRef.current.canSave(CheckSaveEnabled());
    });
  }

  function LoadDevis(devis, isDevis) {
    buttonsRef.current.canSave(false);
    var promises = [GET("devis/getDevisById", { id: devis.id })];

    if (isDevis)
      promises.push(GET("devis/getCLientInfo", { clientId: devis.idClient }));

    setSaveOverride(isDevis);

    return Promise.all(promises).then((data) => {
      bodyRef.current.loadDevis(data[0][0].devisData);
      if (isDevis) {
        headerRef.current.updateClient(data[1][0]);
        headerRef.current.updateDevisInfo(data[0][0]);
      } else {
        headerRef.current.clearDevisInfo();
        headerRef.current.assignNewDevisId();
      }
      headerRef.current.updateDueDatesInfo(data[0][0]);
      headerRef.current.updateTotal(bodyRef.current.getTotal());
      UpdateSaveButton();
    });
  }

  function AddPremadeLines(lines) {
    bodyRef.current.addPremadeLines(lines);
    UpdateSaveButton();
  }

  function AddSpecialLine(type) {
    bodyRef.current.addSpecialLine(type);
    UpdateSaveButton();
  }

  function GetDataForPDF() {
    if (!headerRef.current) return null;
    var header = headerRef.current.getHeaderData();
    var body = bodyRef.current.getBodyData();
    var total = bodyRef.current.getTotal();
    return {
      header,
      body,
      total,
      save: saveOverride,
      logo: logo,
    };
  }

  function CheckSaveEnabled() {
    if (headerRef.current === null || bodyRef.current === null) return false;
    return (
      headerRef.current.isClientSelected() && !bodyRef.current.isBodyEmpty()
    );
  }

  function ReloadTarifs() {
    bodyRef.current.reloadTarifs();
  }
  //#endregion

  return (
    <Box width="100%" height="100%">
      <Stack
        width="100%"
        paddingBottom={10}
        direction="row"
        justifyContent="space-between"
        overflow={"auto"}
      >
        <DevisHeader ref={headerRef} />
        <Stack direction="row" justifyContent="space-around" width="100%">
          <DevisBody
            ref={bodyRef}
            updateTotal={UpdateTotal}
            checkSaveEnabled={UpdateSaveButton}
          />
          <DevisButtons
            ref={buttonsRef}
            reloadTarifs={ReloadTarifs}
            updateClient={UpdateClient}
            updateAccompte={updateAccompte}
            loadDevis={LoadDevis}
            setSaveOverride={setSaveOverride}
            addPremadeLines={AddPremadeLines}
            addSpecialLine={AddSpecialLine}
            getDataForPDF={GetDataForPDF}
            checkSaveEnabled={CheckSaveEnabled}
            selectedLogo={logo}
            onLogoChange={handleLogoChange}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
// <DevisHeader ref={headerRef} client={clientInfo}/>
// class Devis extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			saveOverride: false,
// 			showSnackBar: false,
// 			snackStatus: 'success',
// 			snackMessage: '',
// 		};
// 		this.headerRef = React.createRef();
// 		this.bodyRef = React.createRef();
// 		this.buttonsRef = React.createRef();
// 		this.devisViewerRef = React.createRef();
// 	}

// 	updateClient = (client) => {
// 		return GET("devis/getCLientInfo",{ clientId: client.id })
// 		.then((data) => {
// 			this.headerRef.current.updateClient(data[0]);
// 			this.buttonsRef.current.canSave(this.checkSaveEnabled())
// 		})
// 	}

// 	updateTotal = (total) => this.headerRef.current.updateTotal(total);
// 	updateAccompte = (accompte) => this.headerRef.current.updateAccompte(accompte);
// 	updateSaveButton = () => this.buttonsRef.current.canSave(this.checkSaveEnabled());

// 	loadDevis = (devis, isDevis) => {
// 		var promises = [GET("devis/getDevisById", { id: devis.id })];
// 		if(isDevis) promises.push(GET("devis/getCLientInfo",{ clientId: devis.idClient }))
// 		this.setSaveOverride(isDevis);
// 		return Promise.all(promises)
// 		.then(data => {
// 			this.bodyRef.current.loadDevis(data[0][0].devisData);
// 			if(isDevis) this.headerRef.current.updateClient(data[1][0]);
// 			if(isDevis) this.headerRef.current.updateDevisInfo(data[0][0]);
// 			this.headerRef.current.updateTotal(this.bodyRef.current.getTotal());
// 			this.updateSaveButton();
// 		})

// 	}

// 	addPremadeLines = (lines) => {
// 		this.bodyRef.current.addPremadeLines(lines);
// 		this.updateSaveButton();
// 	}

// 	addSpecialLine = (type) => {
// 		this.bodyRef.current.addSpecialLine(type);
// 		this.updateSaveButton();
// 	}

// 	getDataForPDF = () => {
// 		var header = this.headerRef.current.getHeaderData();
// 		var body = this.bodyRef.current.getBodyData();
// 		var total = this.bodyRef.current.getTotal();
// 		return { header, body, total, save: this.state.saveOverride }
// 	}

// 	checkSaveEnabled = () => {
// 		if(this.headerRef.current === null || this.bodyRef.current === null) return false;
// 		return this.headerRef.current.isClientSelected() && !this.bodyRef.current.isBodyEmpty();
// 	}

// 	setSaveOverride = (save) => {
// 		this.setState({saveOverride: save});
// 	}

// 	getShortDate = (date) => {
// 		const yyyy = date.getFullYear();
// 		let mm = date.getMonth() + 1; // Months start at 0!
// 		let dd = date.getDate();

// 		if (dd < 10) dd = "0" + dd;
// 		if (mm < 10) mm = "0" + mm;

// 		return dd + "/" + mm + "/" + yyyy;
// 	};

// 	reloadTarifs = () => {
// 		this.bodyRef.current.reloadTarifs();
// 	}
// 	//#endregion

// 	render() {
// 		return (
// 			<Box width='100%' height='100%'>
// 				<Stack width="100%" paddingBottom={10} direction="row" justifyContent="space-between" overflow={"auto"}>
// 					<DevisHeader ref={this.headerRef} client={this.state.clientInfo}/>
// 					<Stack className={classNames(styles.sidePanel)} direction='row' justifyContent="space-around">
// 						<DevisBody ref={this.bodyRef} updateTotal={this.updateTotal} checkSaveEnabled={this.updateSaveButton}/>
// 						<DevisButtons ref={this.buttonsRef} reloadTarifs={this.reloadTarifs} updateClient={this.updateClient} updateAccompte={this.updateAccompte} loadDevis={this.loadDevis} addPremadeLines={this.addPremadeLines} addSpecialLine={this.addSpecialLine} openDevisViewer={this.openDevisViewer} getDataForPDF={this.getDataForPDF} checkSaveEnabled={this.checkSaveEnabled} />
// 					</Stack>
// 				</Stack>
// 			</Box>
// 		);
// 	}
// }

// export { Devis };

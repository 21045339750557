import * as React from "react";
import { DELETE, GET, UPDATE } from "../../../utils/AxiosRequest";

import classNames from "classnames";
import { styled, alpha, useTheme } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";

import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  Appointments,
  EditRecurrenceMenu,
  AppointmentTooltip,
  CurrentTimeIndicator,
  ViewSwitcher,
  Toolbar,
  DateNavigator,
  TodayButton,
  Resources,
  AllDayPanel,
} from "@devexpress/dx-react-scheduler-material-ui";

import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
} from "@mui/material";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";

import { createSearchParams } from "react-router-dom";
import { AddDate } from "./AddDate";

import dayjs from "dayjs";

import Session from "react-session-api";
import { publish, subscribe, unsubscribe } from "../../../utils/Event";

import { SEND_NOTIFICATION_SLACK } from "../../../utils/Slack";

export const Calendar = () => {
  const isDesktop = useMediaQuery("(min-width:1065px)");
  const pepoleData = JSON.parse(Session.get("pepoleData"));
  const [currentViewName, setCurrentViewName] = React.useState("Month");
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [calendarData, setCalendarData] = React.useState([]);
  const [displayData, setDisplayData] = React.useState([]);
  const [pepoleList, setPepoleList] = React.useState([]);
  const [currentPepole, setCurrentPepole] = React.useState(
    JSON.parse(
      pepoleData.pepolePlanningFilter === ""
        ? "[-1]"
        : pepoleData.pepolePlanningFilter,
    ),
  );
  const [taskList, setTaskList] = React.useState([]);
  const [currentTask, setCurrentTask] = React.useState(
    pepoleData.planningFilter === ""
      ? [-1]
      : JSON.parse(pepoleData.planningFilter),
  );
  const [resources, setResources] = React.useState([]);
  const [customStyle, setCustomStyle] = React.useState(
    parseInt(pepoleData.transparency) === 1 ? true : false,
  );
  const [vacation, setVacation] = React.useState(true);
  const schedulerRef = React.useRef(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [openAddDate, setOpenAddDate] = React.useState(0);

  const [editOpen, setEditOpen] = React.useState();
  const [editStartDate, setEditStartDate] = React.useState();
  const [editEndDate, setEditEndDate] = React.useState();
  const changeStart = (event) => {
    setEditStartDate(GetStrFromDaysJS(event));
    if (dayjs(event).isAfter(editEndDate)) {
      setEditEndDate(GetStrFromDaysJS(event.add("1", "hour")));
    }
  };
  const changeEnd = (event) => setEditEndDate(GetStrFromDaysJS(event));

  //const [height, setHeight] = React.useState(0);

  React.useLayoutEffect(() => {
    // if(schedulerRef.current !== null){
    //     setHeight(schedulerRef.current.clientHeight);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulerRef.current]);

  React.useEffect(() => {
    let isMounted = true;

    subscribe("onDeletePlanning", (data) => {
      var path = data.detail.custom
        ? "scheduler/deletePlanningSpe"
        : "scheduler/deletePlanning";
      return DELETE(path, { id: parseInt(data.detail.id.split("#")[1]) }).then(
        (_) => {
          return Reload();
        },
      );
    });

    subscribe("onEmployeesChanged", () => {
      Reload();
    });

    subscribe("onTaskEdition", (event) => {
      setEditOpen(event.detail.data);
      setEditStartDate(event.detail.data.startDate);
      setEditEndDate(event.detail.data.endDate);
    });

    Promise.all([
      GET("scheduler/getAllPlanningData", {}),
      GET("scheduler/getAllEmployeData", {}),
      GET("scheduler/getAllTaskTemplateData", {}),
      GET("scheduler/getAllPlanningSpe", {}),
    ]).then((data) => {
      if (isMounted) {
        var list = LoadPepoleData(data[1]);
        LoadRessource(data[2], list);
        var calendarData = LoadCalendarData(data[0], true, list);
        LoadPlannigSpe(data[3], calendarData, list);
      }
    });

    return (_) => {
      isMounted = false;
      unsubscribe("onDeletePlanning", () => {});
      unsubscribe("onEmployeesChanged", () => {});
      unsubscribe("onTaskEdition", () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewName, vacation]);

  React.useEffect(() => {
    if (currentPepole.includes(-1) && currentTask.includes(-1))
      setDisplayData(calendarData);
    else {
      var display = [];
      calendarData.forEach((entry) => {
        if (entry.custom) {
          if (entry.pepoles.some((pepole) => currentPepole.includes(pepole))) {
            display.push(entry);
          }
        } else if (
          (currentPepole.includes(-1) ||
            entry.pepoles.some((pepole) => currentPepole.includes(pepole))) &&
          (currentTask.includes(-1) || currentTask.includes(entry.task))
        ) {
          display.push(entry);
        }
      });
      setDisplayData(display);
    }
  }, [currentPepole, currentTask, calendarData]);

  //#region UTILS
  function LoadCalendarData(data, returnValue, pepoleList) {
    var calendarData = [];

    data.forEach((entry) => {
      //console.log(entry)
      var pepoles = JSON.parse(entry.employe);
      let peopleListNames = "";

      pepoles.forEach((employeeId, i) => {
        let employee = pepoleList.find((element) => element.id === employeeId);
        if (employee) peopleListNames += employee.text + ", ";
      });

      var name = entry.nom === "Autre" ? entry.texte : entry.nom;
      var obj = {
        id: "0#" + entry.id,
        title: entry.nomProjet + " - " + name,
        peopleNames: peopleListNames.slice(0, -2),
        idSousTache: entry.idSousTache,
        task: entry.idTache,
        pepoleList: pepoleList,
        pepoles: pepoles,
        etat: entry.etat,
        color: entry.color,
        custom: false,
        customStyle,
        currentViewName,
        startDate: entry.dateDebut,
        endDate: entry.dateFin,
        idProjet: entry.idProjet,
      };
      calendarData.push(obj);
    });
    if (returnValue) return calendarData;
    setCalendarData(calendarData);
  }

  function LoadPepoleData(data) {
    var pepoleList = [{ id: -1, text: "Tout le monde" }];
    data.forEach((pepole) =>
      pepoleList.push({ id: pepole.id, text: pepole.prenom }),
    );
    setPepoleList(pepoleList);
    return pepoleList;
  }

  function LoadRessource(data, pepoles) {
    var tasks = [
      { id: -2, text: "Personnalisées", color: "#C05C6A" },
      { id: -3, text: "Congés / Absences", color: "#FFA07A" },
    ];
    data = data.sort((a, b) => a.id - b.id);
    data.forEach((task) =>
      tasks.push({ id: task.id, text: task.nom, color: task.color }),
    );
    var resources = [
      { fieldName: "task", title: "Tâche", instances: tasks },
      { fieldName: "pepole", title: "Personne", instances: tasks },
    ];
    tasks.unshift({ id: -1, text: "Toutes les tâches", color: "#000000" });
    setTaskList(tasks);
    setResources(resources);
  }

  function LoadPlannigSpe(data, calendarData, pepoleList) {
    data.forEach((entry) => {
      var obj = {
        id: "1#" + entry.id,
        title: entry.nom,
        task: entry.etat === 0 ? -2 : -3,
        pepoles: [entry.employeId],
        peopleNames: "",
        color: entry.etat === 0 ? "#C05C6A" : "#b8b8b8",
        custom: true,
        customStyle,
        currentViewName,
        startDate: entry.date,
        endDate: entry.dateFin,
        idProjet: -1,
      };
      if (entry.recurrent === 1) {
        var index = dayjs(entry.date).day();
        obj.rRule = "FREQ=WEEKLY;BYDAY=" + WEEKDAY[index] + ";COUNT=1000";
      }
      if (vacation || entry.etat !== 1) calendarData.push(obj);
    });
    setCalendarData(calendarData);
  }

  //#endregion

  function Reload() {
    return Promise.all([
      GET("scheduler/getAllPlanningData", {}),
      GET("scheduler/getAllEmployeData", {}),
      GET("scheduler/getAllTaskTemplateData", {}),
      GET("scheduler/getAllPlanningSpe", {}),
    ]).then((data) => {
      var list = LoadPepoleData(data[1]);
      LoadRessource(data[2], list);
      var calendarData = LoadCalendarData(data[0], true, list);
      LoadPlannigSpe(data[3], calendarData, list);
    });
  }

  function ChangeFilter(data, setter) {
    var array = [];
    if (data.length === 0 || data.at(-1) === -1) {
      array = [-1];
    } else {
      var allIndex = data.findIndex((element) => element === -1);
      if (allIndex !== -1) {
        data.splice(allIndex, 1);
      }
      array = data;
    }
    setter(array);
  }

  function HandleCustomStyle(event) {
    var array = calendarData;
    array.forEach((data) => {
      data.customStyle = event.target.checked;
    });
    setCalendarData([...array]);
    setCustomStyle(event.target.checked);
  }

  function handleVacationSwitch(event) {
    setVacation(!vacation);
  }

  // Effective appointment dates' update
  function handleEditClose(save) {
    if (save) {
      var path = editOpen.custom
        ? "scheduler/updatePlanningSpeDates"
        : "scheduler/updatePlanningDates";
      var params = {
        startDate: editStartDate,
        endDate: editEndDate,
        id: editOpen.id.split("#")[1],
      };
      return UPDATE(path, params).then((_) => {
        setEditOpen();
        Reload();
      });
    }
    setEditOpen();
  }

  const TimeTableCell = (props) => (
    <MonthView.TimeTableCell
      {...props}
      style={{ height: "210px" }}
      onClick={(event) => {
        if (event.detail === 2 || event.ctrlKey) {
          setSelectedDate(props.startDate);
          // New date by default at nine o'clock
          setSelectedDate(props.startDate.setHours(9));
          setOpenAddDate((openAddDate) => openAddDate + 1);
        }
      }}
    />
  );

  function SchedulerView() {
    return (
      <Scheduler
        data={displayData}
        locale="fr"
        firstDayOfWeek={1}
        className={PREFIX}
      >
        <ViewState
          currentDate={currentDate}
          onCurrentDateChange={setCurrentDate}
          currentViewName={currentViewName}
          onCurrentViewNameChange={setCurrentViewName}
        />
        <DayView startDayHour={6} endDayHour={22} />
        <WeekView startDayHour={6} endDayHour={22} />
        <MonthView timeTableCellComponent={TimeTableCell} />
        <AllDayPanel />
        <Toolbar />
        <ViewSwitcher />
        <Appointments appointmentComponent={Appointment} />
        <EditingState />
        <EditRecurrenceMenu />
        <AppointmentTooltip
          headerComponent={Header}
          contentComponent={Content}
          showCloseButton
        />
        <DateNavigator />
        <TodayButton />
        <Resources data={resources} mainResourceName="task" />
        <CurrentTimeIndicator
          shadePreviousCells={true}
          shadePreviousAppointments={true}
          updateInterval={1800}
        />
      </Scheduler>
    );
  }

  function TaskEditionView() {
    return (
      <Dialog
        maxWidth="md"
        open={editOpen}
        onClose={(_) => handleEditClose(false)}
      >
        <DialogTitle>
          <Typography fontSize="1.2em" color="primary">
            Modifier la date
          </Typography>
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack sx={{ width: "100%" }} direction="row" spacing={2}>
              <DateTimePicker
                label="Date & Heure de début"
                value={editStartDate}
                onChange={changeStart}
                ampm={false}
                inputFormat="DD-MM-YYYY  HH:mm"
                shouldDisableTime={(timeValue, clockType) => {
                  return clockType === "minutes" && timeValue % 5;
                }}
                renderInput={(params) => (
                  <TextField fullWidth variant="standard" {...params} />
                )}
              />
              <DateTimePicker
                label="Date & Heure de fin"
                minDateTime={dayjs(editStartDate).minute(
                  dayjs(editStartDate).minute() + 1,
                )}
                value={editEndDate}
                onChange={changeEnd}
                ampm={false}
                inputFormat="DD-MM-YYYY  HH:mm"
                shouldDisableTime={(timeValue, clockType) => {
                  return clockType === "minutes" && timeValue % 5;
                }}
                renderInput={(params) => (
                  <TextField fullWidth variant="standard" {...params} />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={(_) => handleEditClose(false)}>Annuler</Button>
          <Button onClick={(_) => handleEditClose(true)} color="success">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (isDesktop) {
    return (
      <Box sx={{ height: "90vh", overflowY: "scroll" }}>
        <Stack
          direction="column"
          marginBottom={0}
          paddingY={0}
          width="100%"
          height="100vh"
        >
          <Stack
            width="100%"
            direction="row"
            spacing={2}
            marginTop={1}
            justifyContent="center"
            flex="0 1 auto"
          >
            <ResourceSwitcher
              isDesktop={isDesktop}
              data={pepoleList}
              current={currentPepole}
              onChange={(event) => ChangeFilter(event, setCurrentPepole)}
              name="Planning de :"
            />
            <ResourceSwitcher
              isDesktop={isDesktop}
              data={taskList}
              current={currentTask}
              onChange={(event) => ChangeFilter(event, setCurrentTask)}
              name="Tâche :"
            />
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography>Transparent</Typography>
              <Switch checked={customStyle} onChange={HandleCustomStyle} />
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography>Congés</Typography>
              <Switch checked={vacation} onChange={handleVacationSwitch} />
            </Stack>
            <AddDate
              reload={Reload}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              openAddDate={openAddDate}
              setOpenAddDate={setOpenAddDate}
            />
          </Stack>
          <Stack ref={schedulerRef} flex="1 1 auto">
            {SchedulerView()}
          </Stack>
          {editOpen && TaskEditionView()}
        </Stack>
      </Box>
    );
  } else {
    return (
      <Box sx={{ height: "90vh", overflowY: "scroll" }}>
        <Stack spacing={1} width="100%" height="100%">
          <Stack
            width="100%"
            direction="column"
            spacing={1}
            marginTop={2}
            alignItems="center"
            justifyContent="center"
            flex="0 1 auto"
          >
            <ResourceSwitcher
              isDesktop={isDesktop}
              data={pepoleList}
              current={currentPepole}
              onChange={(event) => ChangeFilter(event, setCurrentPepole)}
              name="Planning de :"
            />
            <ResourceSwitcher
              isDesktop={isDesktop}
              data={taskList}
              current={currentTask}
              onChange={(event) => ChangeFilter(event, setCurrentTask)}
              name="Tâche :"
            />
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography>Congés</Typography>
              <Switch checked={vacation} onChange={handleVacationSwitch} />
            </Stack>
            <AddDate
              reload={Reload}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              openAddDate={openAddDate}
              setOpenAddDate={setOpenAddDate}
            />
          </Stack>
          <Stack ref={schedulerRef} flex="1 1 auto">
            {SchedulerView()}
          </Stack>
          {editOpen && TaskEditionView()}
        </Stack>
      </Box>
    );
  }
};

const PREFIX = "Inspiration";
const WEEKDAY = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

const classes = {
  mobile: `${PREFIX}-mobile`,
  container: `${PREFIX}-container`,
  text: `${PREFIX}-text`,
  appointment: `${PREFIX}-appointment`,
  content: `${PREFIX}-content`,
  weekEndCell: `${PREFIX}-weekEndCell`,
  weekEndDayScaleCell: `${PREFIX}-weekEndDayScaleCell`,
  weekDayScaleCell: `${PREFIX}-weekDayScaleCell`,
  opacity: `${PREFIX}-opacity`,
  textCenter: `${PREFIX}-textCenter`,
  header: `${PREFIX}-header`,
};

const StyledAppoinentment = styled(Appointments.Appointment)((info) => {
  const theme = useTheme();
  var color = info.data.color;

  var customStyle = info.data.customStyle;
  const alphaValue = (color, value) => {
    return alpha(color, value);
  };
  return {
    [`&.${classes.appointment}`]: {
      height: info.data.currentViewName === "Month" ? "16px" : "none",
      backgroundColor: customStyle
        ? `${alphaValue(color, 0.0)}`
        : `${alphaValue(color, 0.7)}`,
      border: "none",
      borderLeft: customStyle ? `${alphaValue(color, 1)} 2px solid` : "none",
      "&:hover": {
        backgroundColor: customStyle
          ? `${alphaValue(color, 0.4)}`
          : `${alphaValue(color, 0.5)}`,
      },
      "&:focus": {
        backgroundColor: customStyle
          ? `${alphaValue(color, 0.4)}`
          : `${alphaValue(color, 0.5)}`,
        outline: 0,
      },
    },
    [`&.${classes.appointment} .HorizontalAppointment-title, &.${classes.appointment} .VerticalAppointment-title, &.${classes.appointment} .VerticalAppointment-textContainer`]:
      {
        color: customStyle ? theme.palette.text.primary : "black",
        wordWrap: "break-word",
        textOverflow: "clip",
        whiteSpace: "initial",
      },
  };
});

const Appointment = ({ ...restProps }) => {
  //console.log(restProps);
  var data = restProps.data;
  return (
    <StyledAppoinentment
      title={
        data.peopleNames === ""
          ? data.title
          : data.title + " - " + data.peopleNames
      }
      className={classNames({ [classes.appointment]: true })}
      {...restProps}
    >
      <Stack direction="row" width="100%" height="100%">
        <Box
          width="5px"
          borderRadius={1}
          bgcolor={data.color}
          marginRight={1}
        />
        <Typography variant="body2" fontSize="12px">
          {data.title}
        </Typography>
      </Stack>
    </StyledAppoinentment>
  );
};

const ResourceSwitcher = ({ current, onChange, data, name, isDesktop }) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <Typography
      fontSize={isDesktop ? "1em" : "0.75em"}
      className={classNames(classes.text, isDesktop ? classes.mobile : "")}
    >
      {name}
    </Typography>
    <Select
      sx={{ width: "200px", fontSize: isDesktop ? "1em" : "0.75em" }}
      fullWidth
      multiple
      variant="standard"
      value={current}
      onChange={(e) => onChange(e.target.value)}
    >
      {data.map((resource) => (
        <MenuItem key={resource.id} value={resource.id}>
          {resource.text}
        </MenuItem>
      ))}
    </Select>
  </Stack>
);

const StyledAppointmentTooltipHeader = styled(AppointmentTooltip.Header)(
  () => ({
    [`&.${classes.header}`]: {
      height: "64px",
      backgroundSize: "cover",
    },
  }),
);

const Header = ({ children, appointmentData, ...restProps }) => {
  function DeletePlanning() {
    publish("onDeletePlanning", {
      id: appointmentData.id,
      custom: appointmentData.custom,
    });
    restProps.onHide();
  }

  function editTask() {
    publish("onTaskEdition", { data: appointmentData });
    restProps.onHide();
  }

  return (
    <StyledAppointmentTooltipHeader
      {...restProps}
      className={classNames("classes.header")}
      appointmentData={appointmentData}
    >
      <IconButton color="info" onClick={() => editTask()}>
        <ModeEditOutlineTwoToneIcon />
      </IconButton>
      <IconButton color="info" onClick={() => DeletePlanning()}>
        {" "}
        <DeleteTwoToneIcon />{" "}
      </IconButton>
    </StyledAppointmentTooltipHeader>
  );
};

const Content = ({ children, appointmentData, ...restProps }) => {
  const [employees, setEmployees] = React.useState(appointmentData.pepoles);
  const changeEmployees = (event) => {
    var employeesList = event.target.value;
    var params = {
      employe: JSON.stringify(employeesList),
      id: appointmentData.idSousTache,
    };
    UPDATE("projects/updateSpecificSubtask", params);
    setEmployees(employeesList);
    publish("onEmployeesChanged", {});

    // Notify if a project is set
    const pepoleData = JSON.parse(Session.get("pepoleData"));
    var messageAdded =
      "<sender> t'a assigné à la tâche " +
      (appointmentData.texte && appointmentData.texte !== ""
        ? appointmentData.texte
        : appointmentData.nom);
    var sender = String(pepoleData.prenom).toLowerCase();

    employeesList.forEach(async (element) => {
      var tmp = appointmentData.pepoleList.find((item) => item.id === element);
      if (tmp === undefined) return;

      var user = String(tmp.text).toLowerCase();

      var url = new URL(
        window.location.href.replace(window.location.search, ""),
      );

      await SEND_NOTIFICATION_SLACK(sender, user, messageAdded, url);
    });
  };

  function Navigate(appointmentData) {
    var params = {
      id: appointmentData.idProjet,
      task: appointmentData.task,
    };

    window.open(`/projetInformation/?${createSearchParams(params)}`);
  }

  return (
    <AppointmentTooltip.Content
      {...restProps}
      appointmentData={appointmentData}
    >
      {appointmentData.idSousTache && (
        <Select
          sx={{ marginLeft: 4 }}
          style={{ minWidth: "30%" }}
          variant="standard"
          multiple
          value={employees}
          onChange={changeEmployees}
        >
          {appointmentData.pepoleList.map((employee, i) => (
            <MenuItem value={employee.id} key={employee.id}>
              {employee.text}
            </MenuItem>
          ))}
        </Select>
      )}
      {appointmentData.idProjet !== -1 && (
        <Button
          fullWidth
          sx={{ marginTop: 2 }}
          onClick={() => Navigate(appointmentData)}
        >
          Voir le projet
        </Button>
      )}
    </AppointmentTooltip.Content>
  );
};

function GetStrFromDaysJS(date) {
  return (
    date.$y +
    "-" +
    GetDigits(date.$M + 1) +
    "-" +
    GetDigits(date.$D) +
    "T" +
    GetDigits(date.$H) +
    ":" +
    GetDigits(date.$m) +
    ":" +
    GetDigits(date.$s)
  );
}

function GetDigits(value) {
  var str = "" + value;
  return str.padStart(2, "0");
}

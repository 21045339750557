import React from "react";
import { GET } from "../../../utils/AxiosRequest";

import {
  MenuItem,
  TextField,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Switch,
  useTheme,
} from "@mui/material";

import { FactureViewer } from "../FactureViewer";
import LogoSelector from "../../Administration/Views/LogoSelector";

export const DevisToFacture = (props) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const facture = React.useRef(null);
  const [devisList, setDevisList] = React.useState([]);
  const [devisId, setDevisId] = React.useState("");
  const [commande, setCommande] = React.useState("");
  const [allDevis, setAllDevis] = React.useState(false);
  const [logo, setLogo] = React.useState("inspiration"); // Ajoutez cet état

  const [balanceInvoice, setBalanceInvoice] = React.useState(false);
  const [customLine, setCustomLine] = React.useState(false);
  const [customDesignation, setCustomDesignation] = React.useState("");
  const [customAmount, setCustomAmount] = React.useState(0);

  // Fonction pour gérer le changement de logo
  const handleLogoChange = (value) => {
    setLogo(value);
  };

  const [selectedDevis, setSelectedDevis] = React.useState(
    devisList.find((devis) => devis.id === devisId),
  );

  const handleDevisChange = (event) => {
    const newDevisId = event.target.value;
    changeDevisId(newDevisId);
    const newSelectedDevis = devisList.find((devis) => devis.id === newDevisId);
    setSelectedDevis(newSelectedDevis);
  };

  function changeDevisId(id) {
    setDevisId(id);
    var devis = devisList.find((element) => element.id === id);

    // No dueDateData defined at devis creation OR balance invoice not defined at devis creation OR total percentage with this invoice makes 100%
    // Note: This condition has been copied to the FactureViewer file since the devis is reloaded in that file
    let balanceInvoice =
      !devis.dueDatesData ||
      !devis.dueDatesData[devis.associatedInvoicesCount] ||
      devis.totalAlreadyInvoicedPercentage +
        parseFloat(
          devis.dueDatesData[devis.associatedInvoicesCount].percentage,
        ) >=
        100;

    setBalanceInvoice(balanceInvoice);

    setCommande(devis.numeroCommande ? devis.numeroCommande : "");
  }

  function ChangeCommande(event) {
    setCommande(event.target.value);
  }

  function HandleOpen() {
    return getAlldDevisAndFacturesLines().then((data) => {
      let devisList = filterDevisList(data);
      setDevisList(devisList);
      setOpen(true);
    });
  }

  function HandleClose(generatePDF) {
    setOpen(false);
    if (generatePDF) {
      var factureId = 0;
      var devis = [];
      return GetNewFactureId()
        .then((data) => {
          factureId = data[0].factureId + 1;
          return GetDevisFromDb(devisId);
        })
        .then((data) => {
          devis = data;
          if (selectedDevis.dueDatesData)
            devis[0].dueDate =
              selectedDevis.dueDatesData[selectedDevis.associatedInvoicesCount];
          if (customLine) {
            devis[0].customLine = true;
            devis[0].customDesignation = customDesignation;
            devis[0].customAmount = Number(customAmount);
          }
          return GetClientById(devis[0].idClient);
        })
        .then((client) => {
          facture.current.handleOpen(
            factureId,
            devis,
            client,
            commande,
            true,
            null,
            logo,
          );
          setCommande("");
          setDevisId("");
          setSelectedDevis();
          setBalanceInvoice(false);
          setCustomLine(false);
        });
    }
    setCommande("");
    setDevisId("");
    setSelectedDevis();
    setBalanceInvoice(false);
    setCustomLine(false);
    setLogo("inspiration");
  }

  function filterDevisList(devisList, allDevis) {
    let filteredList = [];
    devisList.forEach((devis) => {
      if (
        (!devis.dueDatesData || devis.dueDatesData === "[]") &&
        !devis.nomFacture
      ) {
        // No dueDates defined, no existing invoice
        if (!filteredList.find((obj) => obj.nom === devis.nom))
          filteredList.push(devis);
      } else if (devis.dueDatesData) {
        const associatedInvoicesCount = devisList.filter(
          (obj) => obj.id === devis.id && devis.nomFacture,
        ).length;
        devis.associatedInvoicesCount = associatedInvoicesCount;
        devis.dueDatesData = JSON.parse(devis.dueDatesData);
        let totalAlreadyInvoicedPercentage = 0;

        if (devis.dueDatesData.length < associatedInvoicesCount) {
          // Last invoice (balance) not defined during devis creation
          totalAlreadyInvoicedPercentage = 100;
        } else {
          for (let i = 0; i < associatedInvoicesCount; i++) {
            totalAlreadyInvoicedPercentage += parseFloat(
              devis.dueDatesData[i].percentage,
            );
          }
        }

        devis.totalAlreadyInvoicedPercentage = totalAlreadyInvoicedPercentage;

        if (
          devis.dueDatesData.length > associatedInvoicesCount ||
          allDevis ||
          totalAlreadyInvoicedPercentage < 100
        ) {
          if (!filteredList.find((obj) => obj.nom === devis.nom)) {
            filteredList.push(devis);
          }
        }
      } else if (allDevis) {
        if (!filteredList.find((obj) => obj.nom === devis.nom))
          filteredList.push(devis);
      }
    });

    return filteredList;
  }

  function toggleMode() {
    return getAlldDevisAndFacturesLines().then((data) => {
      if (allDevis) {
        setAllDevis(false);
        let filteredList = filterDevisList(data, false);
        setDevisList(filteredList);
      } else {
        setAllDevis(true);
        let filteredList = filterDevisList(data, true);
        setDevisList(filteredList);
      }
    });
  }

  //#region AXIOS REQUESTS
  function getAlldDevisAndFacturesLines() {
    return GET("factures/getAlldDevisAndFacturesLines");
  }
  function GetNewFactureId() {
    return GET("factures/getNewFactureId", {});
  }
  function GetDevisFromDb(id) {
    return GET("factures/getDevisById", { id: id });
  }
  function GetClientById(id) {
    return GET("factures/getClientById", { id: id });
  }

  //#endregion

  return (
    <div>
      <Button fullWidth variant="contained" onClick={() => HandleOpen()}>
        Choisir un devis à passer en facture
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => HandleClose(false)}
      >
        <Stack
          spacing={2}
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <DialogTitle color={theme.palette.text.primary}>
            Choisir un devis à passer en facture
          </DialogTitle>
          {allDevis ? (
            <Button
              variant="Text"
              align-content="flex-end"
              onClick={() => toggleMode()}
            >
              Cacher devis facturés
            </Button>
          ) : (
            <Button
              variant="Text"
              align-content="flex-end"
              onClick={() => toggleMode()}
            >
              Afficher tous les devis
            </Button>
          )}
        </Stack>
        <DialogContent>
          <Stack spacing={2} direction="column">
            <TextField
              select
              label="Devis"
              variant="standard"
              value={devisId}
              onChange={handleDevisChange}
            >
              {devisList.map((devis, i) => (
                <MenuItem value={devis.id} key={devis.id}>
                  {devis.nom + " - " + devis.nomEntite}
                </MenuItem>
              ))}
            </TextField>
            {selectedDevis &&
              selectedDevis.dueDatesData &&
              selectedDevis.dueDatesData[
                selectedDevis.associatedInvoicesCount
              ] && (
                <Typography label="Échéance" variant="body1">
                  {selectedDevis.dueDatesData[
                    selectedDevis.associatedInvoicesCount
                  ].information +
                    " " +
                    selectedDevis.dueDatesData[
                      selectedDevis.associatedInvoicesCount
                    ].percentage +
                    "% - " +
                    selectedDevis.dueDatesData[
                      selectedDevis.associatedInvoicesCount
                    ].term}
                </Typography>
              )}
            {balanceInvoice && (
              <Stack direction="row" alignItems="center">
                <Typography variant="body1" color={theme.palette.text.primary}>
                  Ajouter une ligne personnalisée à la facture de solde
                </Typography>
                <Switch
                  checked={customLine}
                  onChange={(event) => setCustomLine(!customLine)}
                />
                <LogoSelector
                  selectedLogo={logo}
                  onLogoChange={handleLogoChange}
                />
              </Stack>
            )}
            {customLine && (
              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  label="Désignation"
                  variant="standard"
                  value={customDesignation}
                  onChange={(event) => setCustomDesignation(event.target.value)}
                ></TextField>
                <TextField
                  label="Montant"
                  variant="standard"
                  value={customAmount}
                  onChange={(event) => setCustomAmount(event.target.value)}
                ></TextField>
              </Stack>
            )}
            {commande && commande !== "" && (
              <TextField
                label="Numéro de commande"
                variant="standard"
                disabled
                value={commande}
                onChange={ChangeCommande}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => HandleClose(false)}>Retour</Button>
          <Button onClick={() => HandleClose(true)} color="success">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
      <FactureViewer ref={facture} />
    </div>
  );
};

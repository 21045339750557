import React from "react";
import { GET } from "../../../utils/AxiosRequest";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LogoSelector from "../../Administration/Views/LogoSelector";

import { AvoirViewer } from "../AvoirViewer";
import { useTheme } from "@mui/material";

export const CreateAvoir = () => {
  const theme = useTheme();
  const avoir = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [factureList, setFactureList] = React.useState([]);
  const [factureId, setFactureId] = React.useState(0);
  const [avoirValue, setAvoirValue] = React.useState(0);
  const [designation, setDesignation] = React.useState("");
  const [logo, setLogo] = React.useState("inspiration");

  const changeFactureId = (event) => setFactureId(event.target.value);
  const changeAvoirValue = (event) => setAvoirValue(event.target.value);
  const changeDesignation = (event) => setDesignation(event.target.value);

  function HandleOpen() {
    return GetAllFactures().then((data) => {
      console.log(data);
      setFactureList(data);
      setOpen(true);
    });
  }

  const handleLogoChange = (value) => {
    setLogo(value);
  };

  function HandleClose(save) {
    if (save) {
      avoir.current.handleOpen(
        factureList[factureId],
        avoirValue,
        designation,
        logo,
      );
    }
    setOpen(false);
  }

  //#region AXIOS REQUESTS
  function GetAllFactures() {
    return GET("factures/getAllFactures");
  }

  //#endregion

  return (
    <div>
      <Button fullWidth variant="contained" onClick={() => HandleOpen()}>
        Créer un avoir pour une facture
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => HandleClose(false)}
      >
        <DialogTitle color={theme.palette.text.primary}>
          Choisir une facturepour créer un avoir
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} direction="column">
            <TextField
              select
              label="Facture"
              variant="standard"
              value={factureId}
              onChange={changeFactureId}
            >
              {factureList.map((facture, i) => (
                <MenuItem value={i} key={facture.id}>
                  {facture.nom + " - " + facture.reference}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              variant="standard"
              label="Montant de l'avoir"
              value={avoirValue}
              onChange={changeAvoirValue}
            ></TextField>
            <TextField
              multiline
              rows={4}
              variant="standard"
              label="Détail de l'avoir"
              value={designation}
              onChange={changeDesignation}
            ></TextField>
          </Stack>
        </DialogContent>
        <LogoSelector onLogoChange={handleLogoChange} selectedLogo={logo} />
        <DialogActions>
          <Button onClick={() => HandleClose(true)} color="success">
            Créer l'avoir
          </Button>
          <Button onClick={() => HandleClose(false)}>Retour</Button>
        </DialogActions>
      </Dialog>

      <AvoirViewer ref={avoir} />
    </div>
  );
};


import * as React from "react";
import { GET, POST, UPDATE } from "../../../utils/AxiosRequest";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  Chip,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";

import Session from "react-session-api";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { SEND_NOTIFICATION_SLACK } from "../../../utils/Slack";

export const AddDate = (props) => {
  const pepoleData = JSON.parse(Session.get("pepoleData"));
  const isDesktop = useMediaQuery("(min-width:1065px)");
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [pepoleList, setPepoleList] = React.useState([]);
  const [name, setName] = React.useState("");
  const [selected, setSelected] = React.useState(-1);
  const [start, setStart] = React.useState(dayjs());
  const [end, setEnd] = React.useState(dayjs(start.hour(start.hour() + 1)));
  const [recurrent, setRecurrent] = React.useState("no");
  const [conge, setConge] = React.useState("no");
  const [projectCreation, setProjectCreation] = React.useState(false);
  const [taskCreation, setTaskCreation] = React.useState(false);
  const [projectName, setProjectName] = React.useState("");
  const [projectLeader, setProjectLeader] = React.useState(pepoleData.id);
  const [category, setCategory] = React.useState();
  const [taskTemplates, setTaskTemplates] = React.useState([]);
  const [autreTaskTemplate, setAutreTaskTemplate] = React.useState([]); // Specific 'autre' task in table templateTask
  const [type, setType] = React.useState("");
  const [text, setText] = React.useState("");
  const [employeesList, setEmployeesList] = React.useState([]);
  const [employees, setEmployees] = React.useState([pepoleData.id]);

  const [clientsList, setClientsList] = React.useState([]);
  const [clientName, setClientName] = React.useState("");
  const [client, setClient] = React.useState("");

  const [projectsList, setProjectsList] = React.useState([]);

  const changeName = (event) => setName(event.target.value);
  const changeSelected = (event) => setSelected(event.target.value);
  const changeStart = (event) => {
    setEnd(event.add("1", "hour"));
    setStart(event);
  };
  const changeEnd = (event) => setEnd(event);
  const changeRecurrent = (event) => setRecurrent(event.target.value);
  const changeConge = (event) => setConge(event.target.value);
  const changeProjectName = (event) => setProjectName(event.target.value);
  const changeProjectLeader = (event) => setProjectLeader(event.target.value);
  const changeCategory = (event) => {
    setCategory(event.target.value);
  };
  const changeType = (event) => {
    setType(event.target.value);
  };
  const changeEmployees = (event) => setEmployees(event.target.value);

  const changeClientName = (event) => setClientName(event.target.value);
  const changeClient = (event) => setClient(event.target.value);

  const changeProjectCreation = (checked) => {
    if (checked) {
      setTaskCreation(false);
    }
    setProjectCreation(checked);
  };

  const changeTaskCreation = (checked) => {
    if (checked) {
      setProjectCreation(false);
    }
    setTaskCreation(checked);
  };

  const [selectedProject, setSelectedProject] = React.useState();
  const changeSelectedProject = (event) => {
    const projectId = event.target.value;
    setSelectedProject(projectId);
    const selectedProjectObject = projectsList.find(
      (project) => project.id === projectId,
    );
    if (selectedProjectObject) {
      setProjectName(selectedProjectObject.nom);
    }
  };

  React.useEffect(() => {
    if (props.openAddDate) {
      setName("");
      setOpen(true);
      // Ensure the dialog is close after validation
      props.setOpenAddDate(0);
    }

    let isMounted = true;
    // Fix the default dates when a date has been selected from the parent (double click or CTRL+click)
    if (props.selectedDate && dayjs(props.selectedDate) - start > 0) {
      changeStart(dayjs(props.selectedDate));
    }
    GET("scheduler/getAllEmployeData", {}).then((data) => {
      if (isMounted) {
        data.unshift({ id: -1, nom: "Tout le monde", prenom: "" });
        setPepoleList(data);
      }
    });

    loadTaskTemplates();
    loadEmployees();
    loadClientsList();
    loadProjects();

    return (_) => {
      isMounted = false;
    };
  }, [props, start]);

  function loadTaskTemplates() {
    // First get templatesoustache so that its possible to populate a task with all its possible soustache templates
    GET("projects/getSubTaskTemplate", {}).then((templatesoustachedata) => {
      GET("projects/getAllTasksTemplate", {}).then((data) => {
        var categories = [];
        data.forEach((taskTemplate) => {
          if (taskTemplate.nom === "Autre") {
            // Set the 'autre' category in case of undefined at task creation
            setAutreTaskTemplate(taskTemplate);
          }

          taskTemplate.types = [];
          templatesoustachedata.forEach((el) => {
            if (el.idTache === taskTemplate.id) {
              taskTemplate.types.push(el);
              if (el.nom === "Autre") {
                taskTemplate.autreType = el;
              }
            }
          });

          if (
            taskTemplate.nom.includes("Tournage") ||
            taskTemplate.nom.includes("Deadline") ||
            taskTemplate.nom.includes("Live") ||
            taskTemplate.nom.includes("Conception") ||
            taskTemplate.nom.includes("Montage") ||
            taskTemplate.nom.includes("Autre") ||
            taskTemplate.nom.includes("Gestion de projet")
          ) {
            categories.push(taskTemplate);
          }
        });
        setTaskTemplates(categories.sort((a, b) => a.id - b.id));
      });
    });
  }

  function loadEmployees() {
    var promises = [GET("projects/getAllEmployeInfo", {})];
    return Promise.all(promises).then((data) => {
      setEmployeesList(data[0]);
    });
  }

  function loadClientsList() {
    var promises = [GET("projects/getClientNom", {})];
    return Promise.all(promises).then((data) => {
      setClientsList(data[0]);
    });
  }

  function loadProjects() {
    // First get templatesoustache so that its possible to populate a task with all its possible soustache templates
    GET("projects/getAllProjects", {}).then((projectsData) => {
      projectsData = projectsData.sort((a, b) => a.nom.localeCompare(b.nom));
      projectsData = projectsData.sort((a, b) => a.transverse - b.transverse);

      setProjectsList(projectsData);
    });
  }

  function HandleOpen() {
    setOpen(true);
  }

  function HandleClose(save) {
    // Ensures the end date is after the start one
    if (start.isAfter(end)) setEnd(start.hour(start.hour() + 1));
    if (save) {
      console.log("saving event ");
      if (projectCreation) {
        return addProjetInDB().then((_) => {
          setOpen(false);
          // Re-int values when closed
          props.setSelectedDate(null);
          setProjectCreation(false);
          setProjectName("");
          setProjectLeader(pepoleData.id);
          setClientName("");
          setClient("");
          setCategory();
          setType("");
          setText("");
          setEmployees([pepoleData.id]);
          setSelectedProject();
          changeStart(dayjs());
        });
      } else if (taskCreation) {
        return insertNewTaskInDb(selectedProject).then((_) => {
          setOpen(false);
          // Re-int values when closed
          props.setSelectedDate(null);
          setTaskCreation(false);
          setProjectName("");
          setProjectLeader(pepoleData.id);
          setClientName("");
          setClient("");
          setCategory();
          setType("");
          setText("");
          setEmployees([pepoleData.id]);
          setSelectedProject();
          changeStart(dayjs());
        });
      } else {
        return POST("scheduler/insertNewPlanningSpe", {
          nom: name,
          date: GetStrFromDaysJS(start),
          dateFin: GetStrFromDaysJS(end),
          employeId: selected,
          recurrent: recurrent === "yes" ? 1 : 0,
          etat: conge === "yes" ? 1 : 0,
        })
          .then(props.reload)
          .then((_) => {
            setOpen(false);
            // Re-int values when closed
            props.setSelectedDate(null);
            setSelectedProject();
            changeStart(dayjs());
          });
      }
    }
    setOpen(false);
    // Re-int values when closed
    props.setSelectedDate(null);
    setProjectName("");
    setProjectLeader(pepoleData.id);
    setClientName("");
    setClient("");
    setCategory();
    setType("");
    setText("");
    setEmployees([pepoleData.id]);

    setSelectedProject();
    setSelectedProject();
    changeStart(dayjs());
  }

  function addProjetInDB(projectInformation) {
    var params = {
      nom: projectName,
      vimeo: "https://vimeo.com/",
      dossier: "",
      idTaches: "",
      idClient: client === "" ? -1 : client,
      nomClient: clientName,
      mailClient: "",
      dateAjout: GetStrFromDaysJS(dayjs()),
      deadline: GetStrFromDaysJS(dayjs()),
      devisId: -1,
      projectLeader: projectLeader,
      transverseProject: false,
    };

    return POST("projects/insertNewProject", params).then(
      (projectCreationResult) => {
        return insertNewTaskInDb(projectCreationResult.insertId);
      },
    );
  }

  function insertNewTaskInDb(projectId) {
    var paramsCategory = {
      // Category here means old table tache (should-be-removed table)
      etat: 0,
      idTache: category ? category.id : autreTaskTemplate.id, // category 'autre' if undefined
      idProjet: projectId,
    };

    return POST("projects/insertNewTask", paramsCategory).then(
      (newCategory) => {
        // Category means old table tache (should be removed table)
        var params = {
          idSousTache: type
            ? type.id
            : getTaskTemplateById(category ? category.id : autreTaskTemplate.id)
                .autreType.id, // type 'Autre' in case of undefined type
          etat: 0,
          employe: JSON.stringify(employees),
          idTache: newCategory.insertId,
          idProjet: projectId,
          texte: !type || type.nom === "Autre" ? text : "",
        };
        POST("projects/insertNewSubTask", params).then((result) => {
          var subtaskParams = {
            id: result.insertId,
          };
          UPDATE("projects/updateSubtaskDefaultOrderIndex", subtaskParams).then(
            () => {
              //from function AddDateInDB(task, subtask, start, end) {
              var dateInDbParams = {
                idProjet: projectId,
                idTache: newCategory.insertId,
                idSousTache: result.insertId,
                dateDebut: start,
                dateFin: end,
              };
              return POST("dates/insertNewPlanning", dateInDbParams).then(
                props.reload,
              );
            },
          );
        });

        // Notify if a project is set
        const pepoleData = JSON.parse(Session.get("pepoleData"));
        var messageAdded =
          "<sender> t'a assigné à la sous-tâche " +
          (text && text !== "" ? text : type.nom) +
          " appartenant à la tâche " +
          (category ? category.nom : "Autre") +
          " du projet " +
          projectName;
        var sender = String(pepoleData.prenom).toLowerCase();

        employees.forEach(async (element) => {
          var tmp = employeesList.find((item) => item.id === element);
          if (tmp === undefined) return;

          var user = String(tmp.prenom).toLowerCase();

          var url = new URL(
            window.location.href.replace(window.location.search, ""),
          );
          url.searchParams.append("id", projectId);
          url.searchParams.append("task", newCategory.insertId);

          await SEND_NOTIFICATION_SLACK(sender, user, messageAdded, url);
        });
      },
    );
  }

  function getTaskTemplateById(id) {
    return taskTemplates.find((element) => element.id === id);
  }

  return (
    <div>
      <Button variant="contained" onClick={() => HandleOpen()}>
        <Typography fontSize={isDesktop ? "1em" : "0.75em"}>
          Ajouter une date
        </Typography>
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => HandleClose(false)}
        padding={0}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginRight={1.8}
        >
          <DialogTitle color={theme.palette.text.primary}>
            Ajouter une date
          </DialogTitle>
          <Stack direction="row" alignItems="center">
            <Typography variant="body1" color={theme.palette.text.primary}>
              Créer projet
            </Typography>
            <Switch
              checked={projectCreation}
              onChange={(event) => changeProjectCreation(!projectCreation)}
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography variant="body1" color={theme.palette.text.primary}>
              Créer tâche
            </Typography>
            <Switch
              checked={taskCreation}
              onChange={(event) => changeTaskCreation(!taskCreation)}
            />
          </Stack>
        </Stack>
        <DialogContent style={{ paddingBottom: "0px" }}>
          {!projectCreation && !taskCreation && (
            <Stack sx={{ width: "100%" }} direction="row" spacing={2}>
              <TextField
                fullWidth
                label="Nom de la date"
                variant="standard"
                value={name}
                onChange={changeName}
              ></TextField>
              <TextField
                fullWidth
                select
                label="Personne"
                variant="standard"
                value={selected}
                onChange={changeSelected}
              >
                {pepoleList.map((pepole) => (
                  <MenuItem key={pepole.id} value={pepole.id}>
                    {pepole.prenom + " " + pepole.nom}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          )}
          {projectCreation && (
            <Stack direction="column">
              <Stack sx={{ width: "100%" }} direction="row" spacing={2}>
                <TextField
                  fullWidth
                  label="Nom du projet"
                  variant="standard"
                  value={projectName}
                  onChange={changeProjectName}
                ></TextField>
                <TextField
                  fullWidth
                  select
                  label="Chargé de projet"
                  variant="standard"
                  value={projectLeader}
                  onChange={changeProjectLeader}
                >
                  {pepoleList.map((pepole) => (
                    <MenuItem key={pepole.id} value={pepole.id}>
                      {pepole.prenom + " " + pepole.nom}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Nom du client"
                  value={clientName}
                  onChange={changeClientName}
                />
                <TextField
                  fullWidth
                  select
                  variant="standard"
                  label="Entreprise du client"
                  value={client}
                  onChange={changeClient}
                >
                  {clientsList &&
                    clientsList.map((client) => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.nomEntite}
                      </MenuItem>
                    ))}
                </TextField>
              </Stack>
            </Stack>
          )}
          {taskCreation && (
            <TextField
              select
              label="Projet"
              style={{ minWidth: "50%", maxWidth: "50%" }}
              sx={{ fontSize: "small" }}
              variant="standard"
              value={selectedProject}
              onChange={changeSelectedProject}
            >
              {projectsList.map((project, i) => (
                <MenuItem value={project.id} key={i}>
                  {project.id === -1 ? "" : project.nom}
                </MenuItem>
              ))}
            </TextField>
          )}
        </DialogContent>
        {(projectCreation || taskCreation) && (
          <DialogContent style={{ paddingTop: "0px" }}>
            <Stack direction="column" alignItems="center">
              <TextField
                fullWidth
                select
                label="Catégorie (Autre par défaut)"
                variant="standard"
                value={category}
                onChange={changeCategory}
              >
                {taskTemplates.map((t, i) => (
                  <MenuItem value={t} key={i} selected={t.nom === "Autre"}>
                    {t.nom}
                  </MenuItem>
                ))}
              </TextField>
              {category && (
                <TextField
                  fullWidth
                  select
                  label="Type"
                  variant="standard"
                  value={type}
                  onChange={changeType}
                >
                  {category &&
                    category.types.map((t, i) => (
                      <MenuItem value={t} key={i}>
                        {t.nom}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              {(!type.nom || "Autre" === type.nom) && (
                <TextField
                  fullWidth
                  label="Texte"
                  variant="standard"
                  selected={text}
                  onChange={(event) => setText(event.target.value)}
                ></TextField>
              )}
              <Stack
                sx={{ width: "100%" }}
                direction="row"
                style={{ marginTop: "6px" }}
                alignItems="center"
                spacing={2}
              >
                <Typography variant="body1" color={theme.palette.text.primary}>
                  Personne
                </Typography>
                <Select
                  style={{ minWidth: "30%" }}
                  variant="standard"
                  multiple
                  value={employees}
                  onChange={changeEmployees}
                >
                  {employeesList.map((employee, i) => (
                    <MenuItem value={employee.id} key={employee.id}>
                      {employee.prenom}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Stack>
          </DialogContent>
        )}

        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="column" spacing={2}>
              <Stack sx={{ width: "100%" }} direction="row" spacing={2}>
                <DateTimePicker
                  label="Date & Heure de début"
                  value={start}
                  onChange={changeStart}
                  ampm={false}
                  inputFormat="DD-MM-YYYY  HH:mm"
                  shouldDisableTime={(timeValue, clockType) => {
                    return clockType === "minutes" && timeValue % 5;
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth variant="standard" {...params} />
                  )}
                />
                <DateTimePicker
                  label="Date & Heure de fin"
                  minDateTime={
                    props.selectedDate && dayjs(props.selectedDate) - start > 0
                      ? dayjs(props.selectedDate).minute(
                          dayjs(props.selectedDate).minute() + 1,
                        )
                      : start.minute(start.minute() + 1)
                  }
                  value={end}
                  onChange={changeEnd}
                  ampm={false}
                  inputFormat="DD-MM-YYYY  HH:mm"
                  shouldDisableTime={(timeValue, clockType) => {
                    return clockType === "minutes" && timeValue % 5;
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth variant="standard" {...params} />
                  )}
                />
              </Stack>
              {!projectCreation && !taskCreation && (
                <Stack direction="row">
                  <Stack
                    sx={{ width: "100%" }}
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Chip label="Récurrent ? " />
                    <ToggleButtonGroup
                      color="primary"
                      value={recurrent}
                      onChange={changeRecurrent}
                    >
                      <ToggleButton value="yes">OUI</ToggleButton>
                      <ToggleButton value="no">NON</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                  <Stack
                    sx={{ width: "100%" }}
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Chip label="Congé / Absences ? " />
                    <ToggleButtonGroup
                      color="primary"
                      value={conge}
                      onChange={changeConge}
                    >
                      <ToggleButton value="yes">OUI</ToggleButton>
                      <ToggleButton value="no">NON</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </LocalizationProvider>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => HandleClose(false)}>Retour</Button>
          <Button onClick={() => HandleClose(true)} color="success">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function GetStrFromDaysJS(date) {
  return (
    date.$y +
    "-" +
    GetDigits(date.$M + 1) +
    "-" +
    GetDigits(date.$D) +
    "T" +
    GetDigits(date.$H) +
    ":" +
    GetDigits(date.$m) +
    ":" +
    GetDigits(date.$s)
  );
}

function GetDigits(value) {
  var str = "" + value;
  return str.padStart(2, "0");
}
